import React from "react"
import Layout from "../components/layout"
import {
  Title,
  Overview,
  Toolset,
  TitleContent,
  Visit,
  WorkedWith,
} from "../components/parts"

const NimbusForWork = () => (
  <Layout title="Nimbus for work">
    <Title heading="Nimbus for work" sub="App for office management" />
    <Overview
      project="nimbus-for-work"
      text={[
        "I designed and implemented an app for Nimbus customers (office managers) to track office cleaning routines and stock levels in the pantry.",
        "The app is not open to the public and is circulated via the enterprise iOS app store.",
      ]}
    />
    <Toolset project="nimbus-for-work" />
    <TitleContent title="Mocks">
      <iframe
        src="/images/nimbus-for-work/nimbus.pdf"
        className="bordered"
        style={{ minWidth: 480, height: 840 }}
        title="mocks"
      />
    </TitleContent>
    <TitleContent title="App">
      <span>
        The app is not available for public usage, it is circulated via
        enterprise iOS app store only.
      </span>
    </TitleContent>
    <Visit project="nimbus-for-work" />
    <WorkedWith project="nimbus-for-work" />
  </Layout>
)

export default NimbusForWork
